<template>
  <a-card>
    <AAnchor class="p-anchor">
      <a-tabs v-model="activeKey" @change="handelTabs" id="gaTab">
        <a-tab-pane key="0" tab="工单概况"></a-tab-pane>
        <a-tab-pane key="1" tab="脚本统计"></a-tab-pane>
        <a-tab-pane key="2" tab="成片统计"></a-tab-pane>
        <a-tab-pane key="3" tab="测试统计"></a-tab-pane>
      </a-tabs>
    </AAnchor>
    <!--<div v-sticky="{ zIndex: 99, top: 0, fixTarget: '#gaTab' }">
      <a-tabs v-model="activeKey" @change="handelTabs" id="gaTab">
        <a-tab-pane key="0" tab="工单概况"></a-tab-pane>
        <a-tab-pane key="1" tab="脚本统计"></a-tab-pane>
        <a-tab-pane key="2" tab="成片统计"></a-tab-pane>
        <a-tab-pane key="3" tab="测试统计"></a-tab-pane>
      </a-tabs>
    </div>-->
    <work-order-list v-if="activeKey=='0'" />
    <script-list v-if="activeKey=='1'" />
    <product-list v-if="activeKey=='2'" />
    <test-list v-if="activeKey=='3'" />
  </a-card>
</template>

<script>
import workOrderList from "./components/work_order_list";
import scriptList from "./components/script_list";
import productList from "./components/product_list";
import testList from "./components/test_list";

export default {
  name: "progressOfTheStatisticsList",
  data() {
    return {
      activeKey: "0"
    };
  },
  components: {
    workOrderList,
    scriptList,
    productList,
    testList
  },
  mounted() {
  },
  methods: {
    handelTabs() {
    }
  }
};
</script>

<style scoped lang="less">
.sticky[fixed] {
  padding-top: 90px;
}

.sticky-fixed {
  background: #fff;
}
</style>
