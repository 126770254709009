<template>
  <div class="top-list">
    <a-row style="padding-top: 20px">
      <a-col :span="16">
        <span class="tableTitle">模板测试情况</span>
      </a-col>
      <a-col :span="6">
        <a-range-picker
            :allowClear="false"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            inputReadOnly
            @change="onChangeDate"
            style="width:260px"
            v-model="search_form.time"
        />
        <p class="picker-text"><span>*</span>统计范围：测试日期=统计日期的测试</p>
      </a-col>
    </a-row>
    <a-spin :spinning="data_loading" tip="数据加载中">
      <div class="data-wrap">
        <div class="box">
          <div class="title">累计测试次数</div>
          <div class="num">{{ Statistics_data.total | numCode }}</div>
        </div>
        <div class="box">
          <div class="title">测试模板数量</div>
          <div class="num">{{ Statistics_data.guideshootCount | numCode }}</div>
        </div>
        <div class="box">
          <div class="title">模板平均测试次数</div>
          <div class="num">{{ Statistics_data.testAvg | numCode }}</div>
        </div>
        <div class="box">
          <div class="title">测试不通过次数</div>
          <div class="num">{{ Statistics_data.noPassCount | numCode }}</div>
        </div>
      </div>
    </a-spin>
    <statistical-list
        class="table-box"
        :list="list"
        :columns="columns"
        :loading="loading"
        :pagination="pagination"
        :is-table-footer="true"
        :footer-data="footerData"
        :is-rate="false"
        @pagination-change="paginationChange"
    />
  </div>
</template>

<script>
import _api from "@/api/contentProduction.js";
import statisticalList from "./statistical_list";
import moment from "moment";
import { Tool } from "@/utils";


export default {
  name: "test_list",
  components: {statisticalList},
  data() {
    return {
      columns: [
        {
          dataIndex: "type",
          title: "测试不通过问题类型",
          width: 200,
        },
        {
          dataIndex: "count",
          title: "测试次数"
        },
        {
          dataIndex: "rate",
          scopedSlots: {customRender: "rate"},
          title: "类型占比"
        }
      ],
      list: [],
      loading: false,
      pagination: {
        page_num: 1,
        page_size: 10,
        total: 0
      },
      search_form: {
        start_time: moment().startOf("month").format("YYYY-MM-DD"),
        end_time: moment().endOf("day").format("YYYY-MM-DD"),
        time: [moment().startOf("month"), moment().endOf("day")],
      },
      data_loading: false,
      Statistics_data: {},
      footerData: []
    };
  },
  mounted() {
    this.getTotalStatistics();
    this.getList();
  },
  methods: {
    getTotalStatistics() {
      let params = {
        statisticsDateStart: this.search_form.start_time,
        statisticsDateEnd: this.search_form.end_time,
      };
      for(let key in params) {
        if(params[key] == "") {
          delete params[key];
        }
      } // 删除空属性
      _api.getTotalStatistics(params).then(res => {
        if(res.code == 200) {
          this.Statistics_data = res.data || {};
        } else {
          this.Statistics_data = {};
          this.$message.error(res.message || "获取测试情况失败");
        }
      });
    }, // 模板测试情况
    getList() {
      this.loading = true;
      let params = {
        page: this.pagination.page_num,
        size: this.pagination.page_size,
        statisticsDateStart: this.search_form.start_time,
        statisticsDateEnd: this.search_form.end_time,
      };
      for(let key in params) {
        if(params[key] == "") {
          delete params[key];
        }
      } // 删除空属性
      _api.getNoPassStatisticsList(params).then(res => {
        if(res.code == 200) {
          let arr = res.data.list || [];
          arr.map(item => {
            item.rate = item.rate ? Tool.roundToNumber(item.rate * 100) : 0;
            return item;
          });
          this.list = arr;
          this.footerData = [];
          arr.length ? this.footerData.push(arr.pop()) : this.footerData = [];
          this.pagination.total = res.data.total || 0;
        } else {
          this.list = [];
          this.pagination.total = 0;
          this.$message.error(res.message || "获取数据失败");
        }
        this.loading = false;
      });
    }, // 进度列表
    onChangeDate(dates) {
      let start_time = "";
      let end_time = "";
      if(dates) {
        start_time = dates[0];
        end_time = dates[1];
      }
      this.search_form.start_time = start_time;
      this.search_form.end_time = end_time;
      this.search_form.time = [start_time, end_time];
      this.pagination.page_num = 1;
      this.pagination.page_size = 10;
      this.getList();
      this.getTotalStatistics();
    },  //日期选择框
    paginationChange(page_num, page_size) {
      this.pagination.page_num = page_num;
      this.pagination.page_size = page_size;
      this.getList();
    }, // 处理分页
  }
};
</script>

<style scoped lang="less">
.picker-text {
  font-size: 12px;
  color: #8b8e92;
  line-height: 16px;

  span {
    font-size: 16px;
    color: red;
    padding-right: 4px;
  }
}

.tableTitle {
  font-size: 19px;
  font-weight: 500;
  color: #333;
}

.bottom-list {
  padding-top: 26px;
}

.top-list {
  padding-bottom: 20px;
}

.data-wrap {
  display: flex;
  justify-content: space-between;
  //margin-bottom: 16px;
  padding: 40px 0;

  .box {
    width: 23%;
    padding: 15px;
    border-radius: 4px;

    &:nth-child(1) {
      background-color: rgba(128, 155, 206, 0.15);

      .title:before {
        background: #9bb9ec;
      }
    }

    &:nth-child(2) {
      background-color: rgba(184, 224, 210, 0.15);

      .title:before {
        background: rgba(184, 224, 210, 1);
      }
    }

    &:nth-child(3) {
      background-color: rgba(234, 196, 213, 0.15);

      .title:before {
        background: rgba(234, 196, 213, 1);
      }
    }

    &:nth-child(4) {
      background-color: rgb(255 238 224);

      .title:before {
        background: #ffc290;
      }
    }
  }

  .title {
    position: relative;
    padding-left: 14px;
    font-size: 14px;
    //font-weight: 500;
    color: #909399;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 7px;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      display: block;
    }
  }

  .num {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    font-size: 30px;
  }
}
</style>
