<template>
  <div>
    <a-form-model
        ref="search_form"
        :model="search_form"
        layout="inline"
        class="search_form"
    >
      <a-row>
        <a-col :span="8">
          <a-form-model-item
              label="统计品牌"
          >
            <a-select
                v-model="search_form.principalId"
                show-search
                option-filter-prop="children"
                placeholder="请输入关键字搜索"
                :filter-option="filterOption"
                style="width: 260px"
            >
              <a-select-option :value="item.id" v-for="item in principal_list" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item
              label="统计日期"
          >
            <a-range-picker
                :allowClear="false"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                inputReadOnly
                @change="onChangeDate"
                style="width:260px"
                v-model="search_form.time"
            />
            <p class="picker-text"><span>*</span>统计范围：下单日期=统计日期的工单</p>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-button
              @click="resetSearch"
              class="mr-10"
          >重置
          </a-button>
          <a-button
              type="primary"
              @click="bindSearch"
          >查询
          </a-button>
        </a-col>
      </a-row>
    </a-form-model>
    <BaseCharts
        class="BaseCharts-box"
        ref="BaseCharts"
        key="line"
        width="100%"
        height="500px"
        :option="barData"
    />
    <statistical-list
        class="table-box"
        :list="list"
        :columns="columns"
        :loading="loading"
        :pagination="pagination"
        tableTitle="品牌模板进度"
        :is-table-footer="true"
        :footer-data="footerData"
        @pagination-change="paginationChange"
    />
  </div>
</template>

<script>
import BaseCharts from "@/components/BaseChart";
import statisticalList from "./statistical_list";
import _api from "@/api/contentProduction.js";
import { merge } from "lodash";
import moment from "moment";
import { Tool } from "@/utils";


export default {
  name: "work_order_list",
  data() {
    return {
      search_form: {
        begin_time: moment().startOf("month").format("YYYY-MM-DD"),
        end_time: moment().endOf("month").format("YYYY-MM-DD"),
        time: [moment().startOf("month"), moment().endOf("month")]
      },
      principal_list: [],
      barData: {
        legend: {
          show: true,
          orient: "horizontal",
          itemGap: 20
        },
        tooltip: {
          trigger: "axis",
        },
        title: {
          text: "",
          top: 0,
          left: 0,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          // boundaryGap: false,
          data: [],
          axisTick: {
            alignWithLabel: true,// x轴是否与柱子对齐
          }
        },
        color: "#4a65bf", // 自定义颜色
        yAxis: {
          min: 0, // 配置 Y 轴刻度最小值
          // max: 2500, // 配置 Y 轴刻度最大值
          type: "value",
          show: true,
          axisLine: {show: false}, // y轴不显示线
          axisTick: {
            show: false, // 不显示y轴刻度
          },
          splitLine: {    //网格线
            lineStyle: {
              type: "dashed"    //设置网格线类型 dotted：虚线   solid:实线
            },
            show: true //隐藏或显示
          }
        },
        series: []
      },
      columns: [
        {
          dataIndex: "principalName",
          width: 140,
          title: "品牌"
        },
        {
          dataIndex: "total",
          width: 120,
          title: "下单模板数量"
        },
        {
          dataIndex: "completedCount",
          title: "已完成"
        },
        {
          dataIndex: "completedRate",
          scopedSlots: {customRender: "completedRate"},
          title: "完成率"
        },
        {
          dataIndex: "adjustCount",
          title: "待调整"
        },
        {
          dataIndex: "testingCount",
          title: "测试中"
        },
        {
          dataIndex: "modifyingCount",
          title: "修改中"
        },
        {
          dataIndex: "onlineCount",
          title: "待上线"
        },
        {
          dataIndex: "notOnlineCount",
          title: "暂不上线"
        },
        {
          dataIndex: "unUploadCount",
          title: "未上传"
        },
      ],
      list: [],
      loading: false,
      pagination: {
        page_num: 1,
        page_size: 10,
        total: 0
      },
      footerData: []
    };
  },
  components: {BaseCharts, statisticalList},
  mounted() {
    this.getList();
    this.getPrincipalsList();
    this.getStatusStatistics();
  },
  methods: {
    getStatusStatistics() {
      let params = {
        principalId: this.search_form.principalId,
        orderDateStart: this.search_form.begin_time,
        orderDateEnd: this.search_form.end_time,
      };
      for(let key in params) {
        if(params[key] == "") {
          delete params[key];
        }
      } // 删除空属性
      _api.getStatusStatistics(params).then(res => {
        if(res.code == 200) {
          const PieList = res.data.yaxis || [];
          const xaxisList = res.data.xaxis || [];
          this.handlePieOptions(PieList, xaxisList, "工单状态分布");
        } else {
          this.pagination.total = 0;
          this.$message.error(res.message || "获取数据失败");
        }
      });
    }, // 图表数据
    getList() {
      this.loading = true;
      let params = {
        page: this.pagination.page_num,
        size: this.pagination.page_size,
        principalId: this.search_form.principalId,
        orderDateStart: this.search_form.begin_time,
        orderDateEnd: this.search_form.end_time,
      };
      for(let key in params) {
        if(params[key] == "") {
          delete params[key];
        }
      } // 删除空属性
      _api.getBrandProgressList(params).then(res => {
        if(res.code == 200) {
          let arr = res.data.list || [];
          arr.map(item => {
            item.completedRate = item.completedRate ? Tool.roundToNumber(item.completedRate * 100) : 0;
            return item;
          });
          this.list = arr;
          this.footerData = [];
          arr.length ? this.footerData.push(arr.pop()) : this.footerData = [];
          this.pagination.total = res.data.total || 0;
        } else {
          this.list = [];
          this.pagination.total = 0;
          this.$message.error(res.message || "获取列表失败");
        }
        this.loading = false;
      });
    }, // 进度列表
    getPrincipalsList() {
      _api.getPrincipalsList().then(res => {
        if(res.code == 200) {
          this.principal_list = res.data || [];
        } else {
          this.principal_list = [];
        }
      });
    }, // 品牌列表
    handlePieOptions(data, list, title = "") {
      this.barData = merge({}, this.barData, {
        title: {
          text: title,
          top: 0,
          left: 0
        },
        xAxis: {
          data: list,
          axisLabel: {
            //x轴文字的配置
            show: true,
            interval: 0,//使x轴文字显示全
          },
        },
        color: "#3b98ff",
        series: [
          {
            name: "",
            type: "bar",
            barWidth: 30, //柱图宽度
            label: {
              show: true,
              position: "top", // 柱子内部：inside，柱子上方：top
              textStyle: {
                fontSize: "14",
                fontWeight: "bolder"
              }
            },
            labelLine: {
              lineStyle: {
                color: "#333"
              },
            },
            data
          }
        ]
      });
    },  // 处理柱状图数据
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }, // 品牌过滤
    bindSearch() {
      this.pagination.page_num = 1;
      this.pagination.page_size = 10;
      this.getList();
      this.getStatusStatistics();
    },  //搜索
    resetSearch() {
      this.search_form = {
        begin_time: moment().startOf("month").format("YYYY-MM-DD"),
        end_time: moment().endOf("month").format("YYYY-MM-DD"),
        time: [moment().startOf("month"), moment().endOf("month")]
      };
      this.pagination.page_num = 1;
      this.pagination.page_size = 10;
      this.getList();
      this.getStatusStatistics();
    }, //重置
    onChangeDate(dates) {
      let start_time = "";
      let end_time = "";
      if(dates) {
        start_time = dates[0];
        end_time = dates[1];
      }
      this.search_form.begin_time = start_time;
      this.search_form.end_time = end_time;
      this.search_form.time = [start_time, end_time];
    },  //日期选择框
    paginationChange(page_num, page_size) {
      this.pagination.page_num = page_num;
      this.pagination.page_size = page_size;
      this.getList();
    }, // 处理分页
  }
};
</script>

<style scoped lang="less">
.search_form {
  padding: 15px 0 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mr-10 {
  margin-right: 10px;
}

.picker-text {
  font-size: 12px;
  color: #8b8e92;
  line-height: 16px;

  span {
    font-size: 16px;
    color: red;
    padding-right: 4px;
  }
}

.BaseCharts-box {
  padding-top: 20px;
}

.table-box {
  padding-top: 20px;
}
</style>
