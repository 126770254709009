<template>
  <div>
    <p class="tableTitle">{{ tableTitle || "" }}</p>
    <a-table
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :rowKey="(record, index) => index"
        :loading="loading"
        bordered
        :scroll="{ y: 500,x:1000 }"
        class="a-tables"
    >
      <div slot="completedRate" slot-scope="text, record">
        <span>{{ record.completedRate ? `${ record.completedRate }%` : "0%" }}</span>
      </div>
      <div slot="backRate" slot-scope="text, record">
        <span>{{ record.backRate ? `${ record.backRate }%` : "0%" }}</span>
      </div>
      <div slot="rate" slot-scope="text, record">
        <span>{{ record.rate ? `${ record.rate }%` : "0%" }}</span>
      </div>
      <div slot="scriptCount">
        <p>脚本数量</p>
        <p class="picker-text"><span>*</span>统计范围：脚本日期=统计日期的任务</p>
      </div>
      <div slot="releaseCount">
        <p>完成数量</p>
        <p class="picker-text"><span>*</span>统计范围：完成日期=统计日期的任务</p>
      </div>
      <div slot="photoCount">
        <p>拍摄数量</p>
        <p class="picker-text"><span>*</span>统计范围：摄影日期=统计日期的任务</p>
      </div>
      <div slot="editorCount">
        <p>剪辑数量</p>
        <p class="picker-text"><span>*</span>统计范围：剪辑日期=统计日期的任务</p>
      </div>
      <div slot="releasePhotoCount">
        <p>完成拍摄</p>
        <p class="picker-text"><span>*</span>统计范围：完成日期=统计日期的任务</p>
      </div>
      <div slot="releaseProductCount">
        <p>完成成片</p>
        <p class="picker-text"><span>*</span>统计范围：完成日期=统计日期的任务</p>
      </div>
      <template slot="footer">
        <a-table
            :columns="columns"
            :pagination="false"
            :showHeader="false"
            :rowKey="(record, index) => index"
            :loading="loading"
            :data-source="footerData"
            :scroll="{ y: 500,x:1000 }"
            class="a-tables-footer"
            v-if="footerData.length"
        >
          <div slot="completedRate" slot-scope="text, record">
            <span>{{ record.completedRate ? `${ record.completedRate }%` : "0%" }}</span>
          </div>
          <div slot="backRate" slot-scope="text, record">
            <span>{{ record.backRate ? `${ record.backRate }%` : "0%" }}</span>
          </div>
          <div slot="rate" slot-scope="text, record">
            <span v-if="isRate">{{ record.rate ? `${ record.rate }%` : "0%" }}</span>
            <span v-else></span>
          </div>
        </a-table>
      </template>
    </a-table>
    <!-- 分页配置 -->
    <base-pagination
        :currentPage="pagination.page_num"
        :pageSize="pagination.page_size"
        :total="pagination.total"
        :options="['10', '30', '50']"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
    >
    </base-pagination>
  </div>
</template>

<script>
export default {
  name: "statistical_list",
  data() {
    return {};
  },
  props: {
    columns: {
      type: Array,
      required: true,
      default: () => []
    },
    list: {
      type: Array,
      required: true,
      default: () => []
    },
    footerData: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
    },
    tableTitle: {
      type: String,
    },
    pagination: {
      type: Object,
      default() {
        return {
          page_num: 1,
          page_size: 10,
          total: 0
        };
      }
    },
    isTableFooter: {
      type: Boolean,
      default: false
    },
    tableKey: {
      type: String,
    },
    isRate: {
      type: Boolean,
      default: true
    },
  },
  mounted() {
  },
  methods: {
    handlePaginationChange(currentPage, pageSize) {
      this.$emit("pagination-change", currentPage, pageSize, this.tableKey);
    },
  }
};
</script>

<style scoped lang="less">
.tableTitle {
  font-size: 19px;
  font-weight: 500;
  color: #333;
}

.picker-text {
  font-size: 12px;
  color: #8b8e92;
  line-height: 16px;

  span {
    font-size: 16px;
    color: red;
    padding-right: 4px;
  }
}

.a-tables /deep/ .ant-table.ant-table-bordered .ant-table-footer {
  border: none;
  padding: 0 !important;
  font-weight: bold;
}

.a-tables-footer /deep/ .ant-table-row-level-0 {
  background: #f9f9f9;
}
</style>
