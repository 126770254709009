<template>
  <div>
    <div class="top-list">
      <a-row style="padding-top: 20px">
        <a-col :span="12">
          <span class="tableTitle">品牌成片进度</span>
        </a-col>
        <a-col :span="6">
          <a-select
              v-model="search_form.principalId"
              show-search
              option-filter-prop="children"
              allow-clear
              placeholder="请输入关键字搜索"
              :filter-option="filterOption"
              style="width: 260px"
              @change="changePrincipalId"
          >
            <a-select-option :value="item.id" v-for="item in principal_list" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="6">
          <a-range-picker
              :allowClear="false"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              inputReadOnly
              @change="onChangeDate(arguments, '0')"
              style="width:260px"
              v-model="search_form.time"
          />
          <p class="picker-text"><span>*</span>统计范围：创建日期=统计日期的成片</p>
        </a-col>
      </a-row>
      <statistical-list
          class="table-box"
          :list="list"
          :columns="columns"
          :loading="loading"
          :pagination="pagination"
          :is-table-footer="true"
          :footer-data="footerData"
          :table-key="'product'"
          @pagination-change="paginationChange"
      />
    </div>
    <!--<a-divider style="height:10px;width: 100vw"/>-->
    <div class="bottom-list">
      <a-row style="padding-top: 20px">
        <a-col :span="12">
          <span class="tableTitle">摄影/剪辑任务统计</span>
        </a-col>
        <a-col :span="6">
          <a-input
              placeholder="输入摄影或剪辑姓名查询"
              v-model.trim="search_form.name"
              style="width: 260px;"
              @keyup.enter="searchList"
              @blur="searchList"
          />
        </a-col>
        <a-col :span="6">
          <a-range-picker
              :allowClear="false"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              inputReadOnly
              @change="onChangeDate(arguments, '1')"
              style="width:260px"
              v-model="search_form.time1"
          />
        </a-col>
      </a-row>
      <statistical-list
          class="table-box"
          :list="script_list"
          :columns="script_columns"
          :loading="script_loading"
          :pagination="script_pagination"
          :is-table-footer="true"
          :footer-data="script_footerData"
          :table-key="'workerStatistics'"
          @pagination-change="paginationChange"
      />
    </div>
  </div>
</template>

<script>
import _api from "@/api/contentProduction.js";
import statisticalList from "./statistical_list";
import moment from "moment";
import { Tool } from "@/utils";

export default {
  name: "product_list",
  data() {
    return {
      columns: [
        {
          dataIndex: "principalName",
          width: 90,
          title: "品牌"
        },
        {
          dataIndex: "total",
          width: 90,
          title: "总计"
        },
        {
          dataIndex: "completedCount",
          width: 90,
          title: "已完成"
        },
        {
          dataIndex: "completedRate",
          scopedSlots: {customRender: "completedRate"},
          width: 90,
          title: "完成率"
        },
        {
          dataIndex: "customerCheckBackCount",
          width: 90,
          title: "客审退回"
        },
        {
          dataIndex: "backRate",
          scopedSlots: {customRender: "backRate"},
          width: 90,
          title: "退回率"
        },
        {
          dataIndex: "toAssignCount",
          width: 90,
          title: "待分配"
        },
        {
          dataIndex: "shootingCount",
          width: 90,
          title: "拍摄中"
        },
        {
          dataIndex: "toEditCount",
          width: 90,
          title: "待剪辑"
        },
        {
          dataIndex: "editingCount",
          width: 90,
          title: "剪辑中"
        },
        {
          dataIndex: "internalCheckingCount",
          width: 90,
          title: "内审中"
        },
        {
          dataIndex: "modifyingCount",
          width: 90,
          title: "修改中"
        },
        {
          dataIndex: "toCheckCount",
          width: 90,
          title: "待审核"
        }
      ],
      list: [],
      loading: false,
      pagination: {
        page_num: 1,
        page_size: 10,
        total: 0
      },
      script_columns: [
        {
          dataIndex: "workerName",
          width: 120,
          title: "员工"
        },
        {
          dataIndex: "shootingCount",
          width: 300,
          slots: {title: "photoCount"},
        },
        {
          dataIndex: "shootingCompletedCount",
          width: 300,
          slots: {title: "releasePhotoCount"},
        },
        {
          dataIndex: "editCount",
          width: 300,
          slots: {title: "editorCount"},
        },
        {
          dataIndex: "completedEditCount",
          width: 300,
          slots: {title: "releaseProductCount"},
        },
      ],
      script_list: [],
      script_loading: false,
      script_pagination: {
        page_num: 1,
        page_size: 10,
        total: 0
      },
      search_form: {
        begin_time: moment().startOf("month").format("YYYY-MM-DD"),
        end_time: moment().endOf("day").format("YYYY-MM-DD"),
        statisticsDateStart: moment().startOf("month").format("YYYY-MM-DD"),
        statisticsDateEnd: moment().endOf("day").format("YYYY-MM-DD"),
        time: [moment().startOf("month"), moment().endOf("day")],
        time1: [moment().startOf("month"), moment().endOf("day")]
      },
      principal_list: [],
      footerData: [],
      script_footerData: [],
    };
  },
  components: {statisticalList},
  mounted() {
    this.getList();
    this.getWorkerStatisticsList();
    this.getPrincipalsList();
  },
  methods: {
    getList() {
      this.loading = true;
      let params = {
        page: this.pagination.page_num,
        size: this.pagination.page_size,
        principalId: this.search_form.principalId,
        statisticsDateStart: this.search_form.statisticsDateStart,
        statisticsDateEnd: this.search_form.statisticsDateEnd,
      };
      for(let key in params) {
        if(params[key] == "") {
          delete params[key];
        }
      } // 删除空属性
      _api.getBrandProcessList(params, "product").then(res => {
        if(res.code == 200) {
          let arr = res.data.list || [];
          arr.map(item => {
            item.completedRate = item.completedRate ? Tool.roundToNumber(item.completedRate * 100) : 0;
            item.backRate = item.backRate ? Tool.roundToNumber(item.backRate * 100) : 0;
            return item;
          });
          this.list = arr;
          this.footerData = [];
          arr.length ? this.footerData.push(arr.pop()) : this.footerData = [];
          this.pagination.total = res.data.total || 0;
        } else {
          this.list = [];
          this.footerData = [];
          this.pagination.total = 0;
          this.$message.error(res.message || "获取列表失败");
        }
        this.loading = false;
      });
    }, // 成片进度列表
    getWorkerStatisticsList() {
      this.script_loading = true;
      let params = {
        page: this.script_pagination.page_num,
        size: this.script_pagination.page_size,
        name: this.search_form.name,
        statisticsDateStart: this.search_form.begin_time,
        statisticsDateEnd: this.search_form.end_time,
      };
      for(let key in params) {
        if(params[key] == "") {
          delete params[key];
        }
      } // 删除空属性
      _api.getWorkerStatisticsList(params).then(res => {
        if(res.code == 200) {
          let arr = res.data.list || [];
          this.script_list = arr;
          this.script_footerData = [];
          arr.length ? this.script_footerData.push(arr.pop()) : this.script_footerData = [];
          this.script_pagination.total = res.data.total || 0;
        } else {
          this.script_list = [];
          this.script_footerData = [];
          this.script_pagination.total = 0;
          this.$message.error(res.message || "获取数据失败");
        }
        this.script_loading = false;
      });
    }, // 脚本进度列表
    getPrincipalsList() {
      _api.getPrincipalsList().then(res => {
        if(res.code == 200) {
          this.principal_list = res.data || [];
        } else {
          this.principal_list = [];
        }
      });
    }, // 品牌列表
    onChangeDate(dates, type) {
      let start_time = "";
      let end_time = "";
      let data = dates[1];
      console.log(data);
      if(data) {
        start_time = data[0];
        end_time = data[1];
      }
      switch(type) {
        case "0":
          this.search_form.statisticsDateStart = start_time;
          this.search_form.statisticsDateEnd = end_time;
          this.search_form.time = [this.search_form.statisticsDateStart, this.search_form.statisticsDateEnd];
          this.pagination.page_num = 1;
          this.pagination.page_size = 10;
          this.getList();
          break;
        case "1":
          this.search_form.begin_time = start_time;
          this.search_form.end_time = end_time;
          this.search_form.time1 = [this.search_form.begin_time, this.search_form.end_time];
          this.script_pagination.page_num = 1;
          this.script_pagination.page_size = 10;
          this.getWorkerStatisticsList();
          break;
      }
    },  //日期选择框
    changePrincipalId() {
      this.pagination.page_num = 1;
      this.pagination.page_size = 10;
      this.getList();
    }, // 选择品牌
    paginationChange(page_num, page_size, key) {
      switch(key) {
        case "product":
          this.pagination.page_num = page_num;
          this.pagination.page_size = page_size;
          this.getList();
          break;
        case "workerStatistics":
          this.script_pagination.page_num = page_num;
          this.script_pagination.page_size = page_size;
          this.getWorkerStatisticsList();
          break;
      }
    }, // 处理分页
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }, // 品牌过滤
    searchList() {
      this.script_pagination.page_num = 1;
      this.script_pagination.page_size = 10;
      this.getWorkerStatisticsList();
    },// 编导搜索
  }
};
</script>

<style scoped lang="less">
.picker-text {
  font-size: 12px;
  color: #8b8e92;
  line-height: 16px;

  span {
    font-size: 16px;
    color: red;
    padding-right: 4px;
  }
}

.tableTitle {
  font-size: 19px;
  font-weight: 500;
  color: #333;
}

.bottom-list {
  padding-top: 26px;
}

.top-list {
  padding-bottom: 20px;
}
</style>

